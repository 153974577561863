export const logo = (
  <>
    <br />
    <div style={{ fontSize: 10, lineHeight: 13 + "px" }}>
      {"            "}+%%%%%%%%%=- :%%%%%%%%-.
      <br />
      {"     "}.*. *%%%%%%%%%%%%+ .%%%%%%%%%%%%- .*.
      <br />
      {"  "}.***. *%%%: .:+%%%# %%% .***.
      <br />
      {""}.***. ++== :%%%= :%%%%%%%%*** .***.
      <br />
      {""}.***. -=++. :%%%+ :%%%%%%%%%%- .***.
      <br />
      {"  "}.***. +%%%. .=#%%#. %%% .***.
      <br />
      {"     "}.*. *%%%%%%%%%%%%*. -%%%%%%%%%%%= .*.
      <br />
      {"           "}*%%%%%%%##*=. :+%%%%%%%%%=:
      <br />
    </div>
  </>
);

export const logoText = [
  "Starting server, please wait.",
  " ",
  " ",
  "            +%%%%%%%%%=-  :%%%%%%%%-.",
  "     .*.   *%%%%%%%%%%%%+ .%%%%%%%%%%%%-   .*. ",
  "  .***.     *%%%:   .:+%%%#           %%%   .***.",
  ".***.       ++==       :%%%= :%%%%%%%%***      .***.",
  ".***.       -=++.      :%%%+ :%%%%%%%%%%-      .***.",
  "  .***.     +%%%.    .=#%%#.          %%%    .***.",
  "     .*.   *%%%%%%%%%%%%*. -%%%%%%%%%%%=   .*. ",
  "           *%%%%%%%##*=. :+%%%%%%%%%=:",
  " ",
  " ",
  "DB Terminal emulator 0.8 developed in React, TypeScript and Css",
  " ",
  "Type help or h to view list of commands",
  " ",
  " ",
];
